.btn
  display: inline-flex
  justify-content: space-between
  align-items: center
  padding: 1rem
  border: none

  @media screen and ( max-width: 800px )
    padding: 0.5rem

  & > *:only-child
    width: 100%
    text-align: center
  span
    line-height: 1.5rem

  i.fal,
  i.far,
  i.fas
    font-size: 1.25em
    line-height: 1.5rem
    min-width: 1rem
    text-align: center

  &.btn-sm
    padding: 0.25rem 0.5rem
    i.fal,
    i.far,
    i.fas
      font-size: 1em
      line-height: 1.5rem

  &.btn-primary,
  &.btn-danger
    color: $white

  &.btn-light
    background-color: rgba($light, 0.33)
    &:hover
      background-color: rgba($light, 0.33)

  &.btn-square
    min-width: 2rem
    max-width: 2rem
    width: 2rem
    min-height: 2rem
    max-height: 2rem
    height: 2rem
    padding: 0
    justify-content: center
    align-items: center

.card
  border: 0
  .card-header
    border: 0
    background-color: inherit
    padding: 1.5rem 1.5rem 0.75rem
  .card-body
    padding: 0.75rem 1.5rem 1.5rem

.bg-light-transparent
  background-color: rgba($light, 0.1)

.card
  box-shadow: 0 0.75rem 2rem rgba($black, .03)

.popover
  border: 0
  box-shadow: 0 0.75rem 2rem rgba($black, .25)
  .popover-arrow:before
    display: none

.w-20
  width: 20%

.modal-header,
.modal-footer
  border: 0
.modal-body
  padding: 2rem

.modal-dialog
  &.modal-fullpage
    width: 95%
    max-width: 100%

.text-sm
  font-size: $font-size-sm

.nav-link
  cursor: pointer

.scroll-snap-x
  scroll-snap-type: x mandatory

.scroll-snap-item-start
  scroll-snap-stop: always
  scroll-snap-align: start

.form-select
  border: none
  padding: 1rem

.cursor-pointer
  cursor: pointer
