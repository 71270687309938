.sidebar
  border-right: 1px solid $gray-200
  margin-right: 1rem
  padding-right: 1rem

  max-width: 300px
  transition: max-width 0.125s

  &.collapsed
    margin-right: 0.5rem
    padding-right: 0.5rem
    max-width: 70px

    .sidebar-user
      visibility: hidden

.sidebar-user
  text-align: center
  min-height: 180px
  padding-bottom: 1rem

.sidebar-main-menu
  display: flex
  justify-content: center
